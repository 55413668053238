import React, { useContext } from 'react';
import { Flex } from 'rebass';
import EventRegisterContext from '../context';
import Button from '../../../Button';

const NavigationButtons = ({ formikProps }) => {
  const { onStepChange, currentStep } = useContext(EventRegisterContext);
  const { values } = formikProps;
  return (
    <Flex
      mt={3}
      justifyContent="center"
      m={-2}
      flexWrap="wrap"
      flexDirection={['column-reverse', null, 'row']}
    >
      {currentStep > 0 && (
        <Button
          variant="outline"
          onClick={() => onStepChange(currentStep - 1)}
          m={2}
          mt={3}
        >
          Back
        </Button>
      )}
      {currentStep < 2 && (
        <Button
          onClick={() => onStepChange(currentStep + 1)}
          m={2}
          mt={3}
          variant={currentStep === 2 ? 'secondary' : 'primary'}
          disabled={
            currentStep === 0 &&
            (![...Array(values.attendees).keys()].every(
              key => values[`attendee-${key}-Name`],
            ) ||
              ![...Array(values.attendees).keys()].every(
                key => values[`attendee-${key}-Email`],
              ))
          }
        >
          {currentStep === 1 ? 'Proceed to payment' : 'Next'}
        </Button>
      )}
    </Flex>
  );
};

NavigationButtons.propTypes = {};

export default NavigationButtons;
