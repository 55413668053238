import React from 'react';
import Text from '../../../Text';
import { format } from 'date-fns';

const EventInfo = ({ event }) => {
  if (event) {
    const { city, state, country, start_date, start_time } = event.data;
    const dateFormat = 'MMM DD, YYYY (ddd)';
    const startDate = `${format(start_date, dateFormat)} @ ${start_time}`;
    const location = `${city}${
      country === 'United States of America'
        ? state
          ? `, ${state}`
          : ''
        : country
        ? `, ${country}`
        : ''
    }`;
    return (
      <div>
        <Text
          color="chestnutRose"
          mb={1}
          style={{ textTransform: 'uppercase' }}
        >
          {startDate}
        </Text>
        <Text as="h3" fontSize={4} color="valhalla" mb={3}>
          {event.data.event_name.text}
        </Text>
        <Text variant="secondary">{location}</Text>
      </div>
    );
  }
  return null;
};

EventInfo.propTypes = {};

export default EventInfo;
