import React from 'react';
import { Flex, Box } from 'rebass';
import styled, { css } from 'styled-components';
import Step from './Step';

const StepWrapper = styled(Box)`
  ${({ theme }) => css`
    @media (min-width: 52em) {
      &:not(:last-of-type) {
        border-right: ${theme.border};
      }
    }
  `}
`;

const StepsHeader = ({ steps, currentStep }) => {
  return (
    <Flex
      m={[-1, null, -3]}
      alignItems={['flex-start', null, 'center']}
      flexDirection={['column', null, 'row']}
    >
      {steps.map((step, idx) => {
        const isActive = currentStep === idx;
        return (
          <StepWrapper key={`step-${idx}`} p={[1, null, 3]}>
            <Step label={step.label} isActive={isActive} index={idx} />
          </StepWrapper>
        );
      })}
    </Flex>
  );
};

StepsHeader.propTypes = {};

export default StepsHeader;
