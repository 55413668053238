import React from 'react';
import { Field } from 'formik';
import { Flex, Box } from 'rebass';
import Input from '../../../../Input';
import Text from '../../../../Text';
import HrWithLabel from '../../../../Layout/Hr/WithLabel';
import ContactInfo from '../../ContactInfo';

const AttendeeInfo = ({ formikProps }) => {
  return (
    <div>
      <Box mb={3}>
        <Field
          name="attendees"
          render={({ field }) => (
            <Input
              label="How many attendees are you registering for?"
              type="number"
              min={1}
              max={5}
              {...field}
            />
          )}
        />
      </Box>
      {formikProps.values.attendees > 4 ? (
        <Box>
          <Text mb={3}>
            If you would like to register more than 4 people for a particular
            workshop/event, please call us directly:
          </Text>
          <ContactInfo showHeading={false} />
        </Box>
      ) : (
        <Flex flexDirection="column" m={-3}>
          {[...Array(formikProps.values.attendees).keys()].map(attendee => (
            <Box p={3} key={`attendee-set-${attendee}`}>
              <HrWithLabel label={`Attendee's #${attendee + 1} Info`} mb={3} />
              <Flex m={-2} mb={2} flexWrap="wrap">
                <Field
                  name={`attendee-${attendee}-Name`}
                  render={({ field }) => (
                    <Box width={[1, 1 / 2]} p={2}>
                      <Input
                        width={1}
                        required
                        placeholder="Full name"
                        type="text"
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Field
                  name={`attendee-${attendee}-Email`}
                  render={({ field }) => (
                    <Box width={[1, 1 / 2]} p={2}>
                      <Input
                        width={1}
                        required
                        placeholder="Email address"
                        type="email"
                        {...field}
                      />
                    </Box>
                  )}
                />
              </Flex>
              <Flex m={-2} flexWrap="wrap">
                <Field
                  name={`attendee-${attendee}-DaytimePhone`}
                  render={({ field }) => (
                    <Box width={[1, 1 / 2]} p={2}>
                      <Input
                        width={1}
                        placeholder="Daytime phone"
                        type="tel"
                        {...field}
                      />
                    </Box>
                  )}
                />
                <Field
                  name={`attendee-${attendee}-EveningPhone`}
                  render={({ field }) => (
                    <Box width={[1, 1 / 2]} p={2}>
                      <Input
                        width={1}
                        placeholder="Evening phone"
                        type="tel"
                        {...field}
                      />
                    </Box>
                  )}
                />
              </Flex>
            </Box>
          ))}
        </Flex>
      )}
    </div>
  );
};

export default AttendeeInfo;
