import React from 'react';
import qs from 'qs';
import PageLayout from '../../components/Layout/Page';
import EventRegister from '../../components/Event/Register';
import { useStaticQuery, graphql } from 'gatsby';
import Text from '../../components/Text';

const EventRegisterPage = ({ location }) => {
  const { search } = location;
  const { eventUid } = qs.parse(search, { ignoreQueryPrefix: true });
  const events = useStaticQuery(graphql`
    {
      allPrismicEvent {
        nodes {
          id
          uid
          data {
            event_name {
              text
            }
            start_date
            start_time
            city
            state
            country
            event_shopify_product
          }
        }
      }
    }
  `);
  const event = events?.allPrismicEvent?.nodes?.find(
    event => event.uid === eventUid,
  );
  return (
    <PageLayout maxWidth={1024} title="Event registration">
      {event ? (
        <EventRegister event={event} />
      ) : (
        <Text fontSize={3} textAlign="center">
          Event not found
        </Text>
      )}
    </PageLayout>
  );
};

EventRegisterPage.propTypes = {};

export default EventRegisterPage;
