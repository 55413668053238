import React from 'react';
import { Flex, Box } from 'rebass';
import { Field } from 'formik';
import Input from '../../../../Input';
import TextArea from '../../../../TextArea';

const AdditionalInfo = () => {
  return (
    <Flex m={-2} flexDirection="column">
      <Field
        name="survey-How-did-your-hear-of-the-event"
        render={({ field }) => (
          <Box p={2}>
            <Input
              width={1}
              type="text"
              placeholder="How did you hear of this BBSH event?"
              {...field}
            />
          </Box>
        )}
      />
      <Field
        name="survey-how-did-your-hear-of-bbsh"
        render={({ field }) => (
          <Box p={2}>
            <Input
              width={1}
              type="text"
              placeholder="How did you hear of Barbara Brennan School of Healing?"
              {...field}
            />
          </Box>
        )}
      />
      <Field
        name="survey-Additional-info-or-observations"
        render={({ field }) => (
          <Box p={2}>
            <TextArea
              width={1}
              placeholder="Any additional info or observations?"
              {...field}
            />
          </Box>
        )}
      />
    </Flex>
  );
};

AdditionalInfo.propTypes = {};

export default AdditionalInfo;
