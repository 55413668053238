import React from 'react';
import Text from '../../../Text';
import { Box } from 'rebass';

const ContactInfo = ({ showHeading }) => {
  return (
    <div>
      <Box mb={4}>
        {showHeading && (
          <Text variant="secondary" mb={1}>
            Want to register by phone?
          </Text>
        )}
        <Text variant="secondary" mb={1} color="chestnutRose">
          TOLL-FREE (US ONLY):
        </Text>
        <Text variant="secondary">800.924.2564</Text>
      </Box>
      <Text mb={1} variant="secondary" color="chestnutRose">
        PHONE:
      </Text>
      <Text variant="secondary">561.620.8767</Text>
    </div>
  );
};

ContactInfo.defaultProps = {
  showHeading: true,
};

export default ContactInfo;
