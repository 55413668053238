import React from 'react';
import { Flex } from 'rebass';
import Hr from '.';
import Text from '../../Text';

const HrWithLabel = ({ label, ...props }) => {
  return (
    <Flex alignItems="center" {...props}>
      <Text
        mr={2}
        variant="secondary"
        style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
      >
        {label}
      </Text>
      <Hr width={1} height={'1px'} />
    </Flex>
  );
};

HrWithLabel.propTypes = {};

export default HrWithLabel;
