import React, { useState } from 'react';
import { Flex } from 'rebass';
import Text from '../../../../Text';
import Button from '../../../../Button';
import Spinner from '../../../../Spinner';
import Axios from 'axios';

const netlifyEndpoint = '/.netlify/functions/shopify-proxy';

const formatAttendeesInfo = values => {
  const keys = Object.keys(values);
  const attendeeKeys = keys.filter(key => key.includes('attendee'));
  const numberOfAttendees = {
    key: 'Number of attendees',
    value: JSON.stringify(values[attendeeKeys.shift()]),
  };
  const attendeesArr = attendeeKeys.reduce((acc, val) => {
    const splitString = val.split('-');
    const attendeeNumber = parseInt(splitString[1]) + 1;
    const attendeeProp = splitString[2];
    const key = `Atteendee #${attendeeNumber}`;
    const attrIdx = acc.findIndex(_attr => _attr.key === key);
    const value = `🟣 ${attendeeProp}: ${values[val]}`;
    if (attrIdx >= 0) {
      acc[attrIdx] = {
        ...acc[attrIdx],
        value: `${acc[attrIdx].value} ${value}`,
      };
    } else {
      acc.push({
        key,
        value,
      });
    }
    return acc;
  }, []);
  const surveyKeys = keys.filter(key => key.includes('survey'));
  const surveysArr = surveyKeys.map(key => ({
    key,
    value: values[key],
  }));
  return [...surveysArr, numberOfAttendees, ...attendeesArr];
};

const Payment = ({ event, formikProps }) => {
  const [paying, pay] = useState(false);
  const [error, setError] = useState(false);
  const [createdOrder, createOrder] = useState(false);
  const { values } = formikProps;
  const variantId = event?.data?.event_shopify_product?.variants[0]?.id;
  const quantity = values.attendees;
  const shippingBillingIsSame = values['shipping-billing-is-same'];
  const customAttributes = formatAttendeesInfo(values);
  const startPayment = () => {
    setError(false);
    pay(true);
    Axios.post(netlifyEndpoint, {
      query: `mutation draftOrderCreate($input: DraftOrderInput!) {
          draftOrderCreate(input: $input) {
            draftOrder {
              id
              invoiceUrl
            }
            userErrors {
              field
              message
            }
          }
        }`,
      variables: {
        input: {
          customAttributes,
          email: values.billing.email,
          billingAddress: values.billing,
          shippingAddress: shippingBillingIsSame
            ? values.billing
            : values.shipping,
          lineItems: [
            {
              variantId: `gid://shopify/ProductVariant/${variantId}`,
              quantity,
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        if (data.errors) {
          setError(true);
          pay(false);
        } else {
          createOrder(true);
          const invoiceUrl =
            data?.data?.draftOrderCreate?.draftOrder?.invoiceUrl;
          if (invoiceUrl) {
            if (typeof window !== 'undefined') {
              window.location.href = invoiceUrl;
            }
          }
        }
      })
      .catch(() => setError(true));
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      {error ? (
        <>
          <Text mb={3} fontSize={3}>
            Oops, an unexpected error occurred
          </Text>
          <Button onClick={startPayment}>Try again</Button>
        </>
      ) : paying ? (
        <>
          <Spinner />
          <Text>Creating order... {createdOrder && '✔️'}</Text>
          {createdOrder && <Text>Redirecting you to our Shopify store...</Text>}
        </>
      ) : (
        <>
          <Text textAlign="center" mb={3}>
            When continuing, your order will be created and you will be
            redirected to our Shopify store. Feel free to go back and review
            your data.
          </Text>
          <Button onClick={startPayment}>Ok, I understand</Button>
        </>
      )}
    </Flex>
  );
};

export default Payment;
