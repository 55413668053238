import React, { useState } from 'react';
import { Flex, Box } from 'rebass';
import { Formik, Form } from 'formik';
import StepsHeader from './Steps/Header';
import Card from '../../Card';
import EventInfo from './EventInfo';
import AttendeeInfo from './Steps/AttendeeInfo';
import AdditionalInfo from './Steps/AdditionalInfo';
import Payment from './Steps/Payment';
import ContactInfo from './ContactInfo';
import EventRegisterContext from './context';
import NavigationButtons from './NavigationButtons';

const steps = [
  {
    label: 'Attendee info',
    component: <AttendeeInfo />,
  },
  {
    label: 'Additional info',
    component: <AdditionalInfo />,
  },
  {
    label: 'Payment',
    component: <Payment />,
  },
];

const EventRegister = ({ event }) => {
  const [currentStep, onStepChange] = useState(0);
  return (
    <EventRegisterContext.Provider value={{ currentStep, onStepChange }}>
      <Box>
        <StepsHeader
          steps={steps}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
        <Flex m={-3} mt={4} flexWrap="wrap">
          <Box width={[1, 3 / 4]} p={3} flex={1}>
            <Card p={4}>
              <Formik
                initialValues={{
                  attendees: 1,
                  'shipping-billing-is-same': true,
                  billing: {
                    countryCode: 'US',
                  },
                  shipping: {
                    countryCode: 'US',
                  },
                }}
              >
                {formikProps => (
                  <Form>
                    {React.cloneElement(steps[currentStep].component, {
                      formikProps,
                      event,
                    })}
                    <NavigationButtons formikProps={formikProps} />
                  </Form>
                )}
              </Formik>
            </Card>
          </Box>
          <Box p={3} width={[1, 1 / 4]}>
            <Box mb={5}>
              <EventInfo event={event} />
            </Box>
            <ContactInfo />
          </Box>
        </Flex>
      </Box>
    </EventRegisterContext.Provider>
  );
};

EventRegister.propTypes = {};

export default EventRegister;
