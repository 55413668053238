import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Text from '../../../../Text';

const Wrapper = styled(Flex)`
  align-items: center;
`;

const Circle = styled(Flex)`
  align-items: center;
  justify-content: center;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.chestnutRose : theme.grays['300']};
`;

const Step = ({ label, index, isActive }) => {
  return (
    <Wrapper>
      <Circle isActive={isActive} mr={2}>
        {index + 1}
      </Circle>{' '}
      <Text
        variant={!isActive ? 'tertiary' : undefined}
        color={isActive ? 'chestnutRose' : undefined}
      >
        {label}
      </Text>
    </Wrapper>
  );
};

Step.propTypes = {};

export default Step;
